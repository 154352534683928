<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="committedYr" @change="filterHandler()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="
                                plantChanged($event);
                            filterHandler();
                                                            ">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">{{
                                plant.plantName
                            }}, {{ plant.regionCode }}</option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            v-model="workcenterId" @change="getMachineFilter()">
                            <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId" :value="wc.workcenterId">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="Machine" class="form-label select-lable lable-text"> Machine </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="machineId"
                            v-model="machineId" @change="filterHandler()">
                            <option v-for="mc in machineList" v-bind:key="mc.machineId" :value="mc.machineId">
                                {{ mc.machineName }}
                            </option>
                            <option disabled v-if="!machineList.length" :key="0" :value="0">No machine exist</option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0.5rem 2rem">
                <div class="even-space-vertical-align">
                    <div class="ma-status">Status :</div>
                    <div class="mat-status-con">
                        <img alt="on track" src="../../../assets/blue-tick.svg" />
                        <span class="ma-status-able mr-lf-3" style="vertical-align: middle">On Track</span>
                    </div>
                    <div class="mat-status-con">
                        <img alt="off track" src="../../../assets/maturity-not-cmpl.svg" />
                        <span class="ma-status-able mr-lf-3" style="vertical-align: middle">Off Track</span>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 1rem 2rem 0 2rem" v-if="machineStatus">
                <div class="even-space-vertical-align">
                    <div class="ma-status">Machine Classification :</div>
                    <div class="">
                        <span class="ma-status" style="vertical-align: middle; font-family: 'BarlowM', sans-serif">
                            {{ machineStatus }}
                        </span>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-action-tracker">
                    <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                        class="machine-action-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="header in machineDetailHeader" :key="header.key">{{ header.label }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="machineDetail && machineDetail.length !== 0">
                            <b-tr v-for="(rowData, index) in machineDetail" :key="index">
                                <b-td v-if="!(index % 3)" rowspan="3" class="machine-action-table-machine">
                                    {{ rowData.pillarName }}
                                </b-td>
                                <b-td class="machine-action-tracker-label">{{ rowData.labels }}</b-td>

                                <b-td>
                                    <div v-if="rowData.jan.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.jan.status">
                                            {{ rowData.jan.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.jan }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.feb.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.feb.status">
                                            {{ rowData.feb.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.feb }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.mar.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.mar.status">
                                            {{ rowData.mar.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.mar }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.apr.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.apr.status">
                                            {{ rowData.apr.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.apr }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.may.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.may.status">
                                            {{ rowData.may.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.may }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.jun.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.jun.status">
                                            {{ rowData.jun.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.jun }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.jul.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.jul.status">
                                            {{ rowData.jul.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.jul }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.aug.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.aug.status">
                                            {{ rowData.aug.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.aug }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.sep.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.sep.status">
                                            {{ rowData.sep.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.sep }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.oct.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.oct.status">
                                            {{ rowData.oct.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.oct }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.nov.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.nov.status">
                                            {{ rowData.nov.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.nov }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="rowData.dec.hasOwnProperty('status')" class="value-td">
                                        <div :class="rowData.dec.status">
                                            {{ rowData.dec.value }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div>{{ rowData.dec }}</div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="machineDetail && machineDetail.length === 0">
                            <tr>
                                <td class="text-center" style="height: 380px" rowspan="6"
                                    :colspan="machineDetailHeader.length">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date">No Data Found</div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import moment from 'moment';
export default {
    name: "ActionTrackerMachineWise",
    data() {
        return {
            showLoader: true,
            plantList: [],
            workCenterList: [],
            workcenterId: 2,
            plantId: {},
            machineId: 1,
            machineList: [],
            committedYear: [],
            committedYr: new Date().getFullYear(),
            machineDetailHeader: [
                {
                    key: "pillarName",
                    label: "Pillar",
                    sortable: false
                },
                {
                    key: "labels",

                    sortable: false
                },
                {
                    key: "jan",
                    label: "Jan",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "Feb",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "Mar",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "Apr",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "May",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "Jun",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "Jul",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "Aug",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "Sep",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "Oct",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "Nov",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "Dec",
                    sortable: false,
                    variant: "success"
                }
            ],
            machineStatus: "",
            machineDetail: []
        };
    },
    components: {
        Breadcrumb,
        Loader
    },

    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 4
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization - Step Audit by Machine",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        setTimeout(() => {
            this.showLoader = false;
        }, 1000);
        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"])
    },

    methods: {
        getCommittedYear() {
            console.log("getCommittedYear");
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            console.log("getPlantDetails");

            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                this.workcenterId = data.data[0].workcenterId;
                this.getMachineFilter();
            });
        },
        getMachineFilter() {
            console.log("getMachineFilter");
            let machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.workcenterId,
                regionId: this.plantId.regionId
            };
            console.log("machinePayload", machinePayload);
            ApiCalls.httpCallDetails(Api.MRMACHINEDROPDOWN, "post", machinePayload).then((data) => {
                if (data.data && data.data.length) {
                    this.machineList = data.data;
                    this.machineId = data.data[0].machineId;
                    this.filterHandler();
                } else {
                    this.machineList = [];
                    this.machineId = 0;
                    this.machineDetail = [];
                    this.machineStatus = "";
                }
            });
        },

        filterHandler() {
            console.log("filterHandler");

            let machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.workcenterId,
                machineId: this.machineId,
                committedYear: this.committedYr
            };
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.MRACTIONSCOREPILLARWISE, "post", machinePayload).then((data) => {
                console.log(data);
                this.showLoader = false;
                let resData = data.data;
                let temp = [];
                this.machineStatus = resData.machineStatus;
                resData.machineActionScore.forEach((machineData, index) => {
                    if (Object.hasOwnProperty.call(machineData, "isApproved") && machineData.isApproved)
                        temp.push({
                            pillarName: machineData.pillarCode,
                            labels: "Target",
                            ...machineData.target
                        });
                    else {
                        let tempTarget = {};
                        for (const month in machineData.current) {
                            if (Object.hasOwnProperty.call(machineData.current, month)) {
                                tempTarget[month] = 0;
                            }
                        }
                        temp.push({
                            pillarName: machineData.pillarCode,
                            labels: "Target",
                            ...tempTarget
                        });
                    }

                    let tempCurrent = {},
                        flag = true;
                    for (const month in machineData.current) {
                        if (Object.hasOwnProperty.call(machineData.current, month)) {
                            const current = machineData.current[month];
                            const target = machineData.target[month];
                            const currentMonth = moment(new Date()).format("MMM").toLowerCase();

                            if (machineData.isApproved && (month === currentMonth || flag)) {
                                flag = false;
                                tempCurrent[month] = { value: current, status: current < target ? "offtrack" : "ontrack" };
                            } else tempCurrent[month] = { value: current, status: "default" };
                        }
                    }
                    temp.push({
                        labels: "Current",
                        ...tempCurrent
                    });
                    temp.push({
                        labels: "Audit Score",
                        ...machineData.auditScore
                    });
                });
                console.log(temp);
                this.machineDetail = temp;
            });
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 25%;
    padding: 0 1%;
}

.value-td {
    display: flex;
    width: inherit;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child,
#machine-action-tracker th:nth-child(2) {
    background-color: white !important;
    font-weight: medium;
}

#machine-action-tracker tr:nth-child(3n-2) td:first-child {
    border-bottom: 1px solid #eae6e6 !important;
}

#machine-action-tracker tr:nth-child(3n) td {
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-action-tracker-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table {
    max-height: 37rem !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.default {
    width: 2rem;
    background: transparent;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #333 !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
